/**
 * @name 奖品管理
 */
import request from '@/utils/request'

export const getPrizesSetup = () => {
  return request({
    method: 'GET',
    url: '/cms/prizes/setup'
  })
}

export const getPrizesList = (params) => {
  return request({
    method: 'GET',
    url: '/cms/prizes',
    params
  })
}

export const getPrizesEditDetails = (id) => {
  return request({
    method: 'GET',
    url: `/cms/prizes/${id}/preview`
  })
}

export const getPrizesDetails = (id) => {
  return request({
    method: 'GET',
    url: `/cms/prizes/${id}`
  })
}

export const getPrizesCollections = () => {
  return request({
    method: 'GET',
    url: '/cms/prizes/collections'
  })
}

export const deletePrizesDetails = (id) => {
  return request({
    method: 'DELETE',
    url: `/cms/prizes/${id}`
  })
}

export const postPrizesFormData = (data) => {
  return request({
    method: 'POST',
    url: 'cms/prizes',
    data
  })
}

export const putPrizesFormData = (data, id) => {
  return request({
    method: 'PUT',
    url: `cms/prizes/${id}`,
    data
  })
}

export const updatePrizesOnline = (id) => {
  return request({
    method: 'POST',
    url: `cms/prizes/${id}/online`
  })
}

export const updatePrizesOffline = (id) => {
  return request({
    method: 'POST',
    url: `cms/prizes/${id}/offline`
  })
}

export const getPrizesShops = (type) => {
  return request({
    method: 'GET',
    url: `cms/prizes/prizeable_id?prizeable_type=${type}`
  })
}
/**
 * @name 抽奖记录
 */

export const getPrizeDrawsSetup = () => {
  return request({
    method: 'GET',
    url: '/cms/prize_draws/setup'
  })
}

export const getPrizeDrawsList = (params) => {
  return request({
    method: 'GET',
    url: '/cms/prize_draws',
    params
  })
}

export const getPrizeDrawsDetails = (id) => {
  return request({
    method: 'GET',
    url: `/cms/prize_draws/${id}`
  })
}

export const deletePrizeDrawsDetails = (id) => {
  return request({
    method: 'DELETE',
    url: `/cms/prize_draws/${id}`
  })
}

/**
 * @name 中奖记录
 */

export const getRewardsSetup = () => {
  return request({
    method: 'GET',
    url: '/cms/rewards/setup'
  })
}

export const getRewardsList = (params) => {
  return request({
    method: 'GET',
    url: '/cms/rewards',
    params
  })
}

export const getRewardsDetails = (id) => {
  return request({
    method: 'GET',
    url: `/cms/rewards/${id}`
  })
}

/**
 * @name 抽奖机会
 */

export const getLuckyDrawSetup = () => {
  return request({
    method: 'GET',
    url: '/cms/lucky_draw_chances/setup'
  })
}

export const getLuckyDrawList = (params) => {
  return request({
    method: 'GET',
    url: '/cms/lucky_draw_chances',
    params
  })
}

export const getLuckyDrawEditDetails = (id) => {
  return request({
    method: 'GET',
    url: `/cms/lucky_draw_chances/${id}/preview`
  })
}

export const getLuckyDrawDetails = (id) => {
  return request({
    method: 'GET',
    url: `/cms/lucky_draw_chances/${id}`
  })
}

export const deleteLuckyDrawDetails = (id) => {
  return request({
    method: 'DELETE',
    url: `/cms/lucky_draw_chances/${id}`
  })
}

export const postLuckyDrawFormData = (data) => {
  return request({
    method: 'POST',
    url: 'cms/lucky_draw_chances',
    data
  })
}

export const putLuckyDrawFormData = (data, id) => {
  return request({
    method: 'PUT',
    url: `cms/lucky_draw_chances/${id}`,
    data
  })
}
